<template>
  <page-content>
    <page-section :margin-top="'xl'">
      <content-column>
        <content-entry-header>Delete Account</content-entry-header>
      </content-column>
    </page-section>

    <page-section :margin-top="'md'">
      <page-section-header :heading="'We are sorry to see you leave!'" />
    </page-section>

    <page-section :margin-top="'sm'">
      <content-column>
        <p>
          If you have any feedback or questions about your experience, we'd love to hear from you.
          To ensure the security of your information, account deletions cannot be processed
          automatically. If you wish to delete your account, please reach out to our customer
          support team. Our team is here to assist you with any questions or concerns and guide you
          through the account deletion process.
        </p>
        <br />
        <p>
          Please allow up to 48 hours for our team to process your request. Thank you for being a
          part of our community, and we hope to see you again in the future!
        </p>
      </content-column>
    </page-section>
    <div v-if="ready">
      <page-section :margin-top="'md'">
        <content-column>
          <standard-button :label="'Send us a message'" :on-click="onSendMessageClicked" />
        </content-column>
      </page-section>
    </div>
    <div v-else-if="showEmail">
      <page-section :margin-top="'md'">
        <content-column>
          <p class="text-fl-xl">
            To contact our customer support, please email <b>support@scottsbasslessons.com</b>.
          </p>
        </content-column>
      </page-section>
    </div>
  </page-content>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref} from 'vue';
import ContentEntryHeader from '../../../../content-entry/partials/header/ContentEntryHeader.vue';
import PageContent from '../../../../core/page/PageContent.vue';
import PageSection from '../../../../core/page/PageSection.vue';
import PageSectionHeader from '../../../../core/page/PageSectionHeader.vue';
import ContentColumn from '../../../../core/compositions/ContentColumn.vue';
import StandardButton from '../../../../core/button/StandardButton.vue';
import {useFullScreenLoader} from '../../../../vue-composition/loader/loader';
import {useStore} from '../../../../../store/store';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    PageSectionHeader,
    ContentColumn,
    ContentEntryHeader,
    StandardButton
  },

  setup() {
    const loader = useFullScreenLoader();
    const store = useStore();
    const emailTimeout = ref(false);
    const ready = computed(() => {
      return store.getters['helpScout/ready'];
    });
    const showEmail = computed(() => {
      return emailTimeout.value && !ready.value;
    });

    onMounted(async () => {
      loader.setLoading(true);

      Beacon('navigate', '/ask/');

      window.setTimeout(() => {
        emailTimeout.value = true;
        loader.setLoading(false);
      }, 1000);
    });

    const onSendMessageClicked = () => {
      if (typeof Beacon !== 'undefined') {
        Beacon('open');
        Beacon('navigate', '/ask/message/');
      }
    };

    return {
      onSendMessageClicked,
      ready,
      showEmail
    };
  }
});
</script>
