<template>
  <div>
    <access-pass-list
      v-if="accessPasses !== undefined"
      :access-passes="accessPasses"
      :resubscribe-offers="resubscribeOffers"
    />
    <current-offers-list
      v-if="upgradeOffers.length > 0 || addOnOffers.length > 0"
      :upgrade-offers="upgradeOffers"
      :add-on-offers="addOnOffers"
    />
    <resubscribe-offer-list :offers="resubscribeOffers" />
    <backend-error
      :label="'Could not fetch your account status'"
      :error="error"
      :cta="'Please contact customer support'"
    />
    <page-section :margin-top="'xl'" :margin-bottom="'md'">
      <content-column :width="'wide'">
        <standard-button color="cta" url="/account/delete" label="Delete Account" />
      </content-column>
    </page-section>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from 'vue';
import AccessPassList from './partials/AccessPassList.vue';
import {getAccessPassesForCurrentUser} from '../../../../../backend/access-pass/access-pass-query';
import {AccessPassStatus} from '../../../../../backend/access-pass/access-pass-types';
import {useFullScreenLoader} from '../../../../vue-composition/loader/loader';
import {
  standardNotification,
  useNotificationPopup
} from '../../../../vue-composition/notification-popup/notification-popup';
import CurrentOffersList from './partials/CurrentOffersList.vue';
import {UpgradeOffer} from '../../../../../backend/offer/upgrade-offer-types';
import ResubscribeOfferList from './partials/ResubscribeOfferList.vue';
import BackendError from '../../../../generic/backend-error/BackendError.vue';
import {useBackendError} from '../../../../vue-composition/backend-error/backend-error';
import {ResubscribeOffer} from '../../../../../backend/offer/resubscribe-offer-types';
import {UpgradePair, getUpgradesInProgress} from '../utils';
import {getUpgradeOffersForCurrentUser} from '../../../../../backend/offer/upgrade-offer-utils';
import {getResubscribeOffersForCurrentUser} from '../../../../../backend/offer/resubscribe-offer-utils';
import {AddOnOffer} from '../../../../../backend/offer/addon-offer-types';
import {getAddOnOffersForCurrentUser} from '../../../../../backend/offer/addon-offer-utils';
import StandardButton from '../../../../core/button/StandardButton.vue';
import PageSection from '../../../../core/page/PageSection.vue';
import ContentColumn from '../../../../core/compositions/ContentColumn.vue';

export default defineComponent({
  components: {
    AccessPassList,
    CurrentOffersList,
    ResubscribeOfferList,
    BackendError,
    StandardButton,
    PageSection,
    ContentColumn
  },
  props: {
    result: {type: String, required: true},
    userDateCreated: {type: String, required: true},
    serverDate: {type: String, required: true}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const accessPasses = ref<ReadonlyArray<AccessPassStatus> | undefined>(undefined);
    const upgradeOffers = ref<ReadonlyArray<UpgradeOffer>>([]);
    const addOnOffers = ref<ReadonlyArray<AddOnOffer>>([]);
    const resubscribeOffers = ref<ReadonlyArray<ResubscribeOffer>>([]);
    const upgrades = ref<ReadonlyArray<UpgradePair>>([]);
    const notification = useNotificationPopup();
    const error = useBackendError(true);

    onMounted(async () => {
      switch (props.result) {
        case 'updateSuccess':
          notification.notify(standardNotification('Subscription updated.'));
          break;
        case 'resubscribeSuccess':
          notification.notify(standardNotification('Thank you for your purchase! Welcome back!'));
          break;
        case 'upgradeSuccess':
          notification.notify(standardNotification('Thank you for your purchase!'));
          break;
        case 'cancelSuccess':
          notification.notify(standardNotification('Subscription cancelled.'));
          break;
      }

      /*
        If the user has updates in progress, omit the cancelling source passes
        from the access pass list. (We do this to avoid confusing the customers.)
      */
      loader.setLoading(true, 'Loading your subscriptions');
      const userPasses = await getAccessPassesForCurrentUser().catch((_error: Error) => {
        error.set(_error);
        return [];
      });
      loader.setLoading(false);

      loader.setLoading(true, 'Loading your subscriptions');
      upgrades.value = await getUpgradesInProgress(userPasses).catch((_error: Error) => {
        error.set(_error);
        return [];
      });
      loader.setLoading(false);
      accessPasses.value = userPasses.filter(p => {
        const passIsUpgradeSource = upgrades.value.find(u => u.sourceId === p.id) !== undefined;
        return !passIsUpgradeSource;
      });

      loader.setLoading(true, 'Loading your subscriptions');
      upgradeOffers.value = await getUpgradeOffersForCurrentUser(
        ['account'],
        props.userDateCreated,
        props.serverDate
      ).catch((_error: Error) => {
        error.set(_error);
        return [];
      });
      loader.setLoading(false);

      loader.setLoading(true, 'Loading your subscriptions');
      addOnOffers.value = await getAddOnOffersForCurrentUser(
        false,
        ['account'],
        props.userDateCreated,
        props.serverDate
      ).catch((_error: Error) => {
        error.set(_error);
        return [];
      });
      loader.setLoading(false);

      loader.setLoading(true, 'Loading your subscriptions');
      resubscribeOffers.value = await getResubscribeOffersForCurrentUser(
        ['account'],
        props.userDateCreated,
        props.serverDate
      ).catch((_error: Error) => {
        error.set(_error);
        return [];
      });
      loader.setLoading(false);
    });

    return {
      loader,
      accessPasses,
      upgradeOffers,
      addOnOffers,
      resubscribeOffers,
      error
    };
  }
});
</script>
